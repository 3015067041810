import { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import dayjs, { Dayjs } from 'dayjs';
import useSWR from 'swr';
import { Panel } from '../Common/Panel';
import { LoadingIndicator } from '../Common/LoadingIndicator';
import { SectionHeading } from '../Typography/SectionHeading';
import { StackedList, StackedListItem } from '../Table/StackedList';
import { formatName } from '../../utilities/formatName';
import { useAppContext } from '../../context/appContext';
import authenticatedFetcher from '../../data/authenticatedFetcher';
import { HttpEndpoints } from '../../data/httpEndpoints';
import { useErrorPopupContext } from '../../context/errorPopupContext';
import { UserBirthday } from '../../typings/backend-types';
import { DynamicExportButton } from '@components/Common/ExportImport/DynamicExportButton';

export const EmployeeBirthdaysTile: FunctionComponent = () => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'dashboard.employeeBirthdays',
  });

  const { organizationId } = useAppContext();
  const { setErrorMessage } = useErrorPopupContext();

  const { data: usersWithUpcomingBirthday = [], isLoading: isLoadingUsers } =
    useSWR<UserBirthday[]>(
      () => HttpEndpoints.UserEndpoints.getUserBirthdaysForOrg(organizationId),
      authenticatedFetcher,
      {
        onError: () => setErrorMessage(t('couldNotGetBirthdays')),
      },
    );

  function formatBirthday(birthday: Dayjs): string {
    if (birthday.isSame(dayjs(), 'day')) {
      return t('today');
    }
    if (birthday.isSame(dayjs().add(1, 'day'), 'day')) {
      return t('tomorrow');
    }
    return birthday.format('DD.MM.');
  }

  function getAgeSuffix(nextBirthday: Dayjs, dateOfBirth: Dayjs): string {
    const age = nextBirthday.year() - dateOfBirth.year();
    const suffixKey =
      age % 10 === 1
        ? 'stBirthday'
        : age % 10 === 2
        ? 'ndBirthday'
        : age % 10 === 3
        ? 'rdBirthday'
        : 'thBirthday';

    return `${t(suffixKey, { age })}`;
  }

  const processedUsers = usersWithUpcomingBirthday.map((user) => ({
    name: formatName(user),
    formattedBirthday: formatBirthday(dayjs(user.nextBirthday)),
    ageSuffix: getAgeSuffix(dayjs(user.nextBirthday), dayjs(user.dateOfBirth)),
  }));

  if (isLoadingUsers) {
    return (
      <Panel className="break-inside-avoid">
        <LoadingIndicator />
      </Panel>
    );
  }

  return (
    <Panel className="break-inside-avoid">
      <div className="flex items-center">
        <SectionHeading className="flex-grow mb-0 mr-3">
          {t('employeeBirthdays')}
        </SectionHeading>
        <DynamicExportButton
          small
          data={processedUsers.map(
            ({ name, formattedBirthday, ageSuffix }) => ({
              [t('userNameColumn')]: name,
              [t('birthdayColumn')]: `${formattedBirthday} ${ageSuffix}`,
            }),
          )}
          exportFileName={t('employeeBirthdays')}
        />
      </div>
      <StackedList>
        {processedUsers.length === 0 && (
          <StackedListItem className="text-sm">
            {t('noBirthdays')}
          </StackedListItem>
        )}
        {processedUsers.map(({ name, formattedBirthday, ageSuffix }) => (
          <StackedListItem key={name} className="text-sm" paddingY={3}>
            {name}
            <span>
              {formattedBirthday} ({ageSuffix})
            </span>
          </StackedListItem>
        ))}
      </StackedList>
    </Panel>
  );
};
