import dayjs from 'dayjs';
import { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';

import useSWR from 'swr';
import { useErrorPopupContext } from '../../context/errorPopupContext';
import { StudentSort, StudentWithExamDate } from '../../typings/backend-types';
import { displayDateFormat } from '../../utilities/dateFormat';
import { Routes } from '../../utilities/routes';
import { formatNameWithLabel } from '../../utilities/students/formatNameWithLabel';
import { Panel } from '../Common/Panel';
import { PagedTable } from '../Table/PagedTable';
import { Column } from '../Table/SortedFilteredTable/SortedFilteredTable';
import { SectionHeading } from '../Typography/SectionHeading';
import { HttpEndpoints } from '../../data/httpEndpoints';
import { useAppContext } from '../../context/appContext';
import { DynamicExportButton } from '@components/Common/ExportImport/DynamicExportButton';
import authenticatedFetcher from 'data/authenticatedFetcher';
import { formatName } from 'utilities/formatName';

export const MyStudentsPriorityTile: FunctionComponent = () => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'dashboard.studentsPriority',
  });
  const { setErrorMessage } = useErrorPopupContext();
  const { userId } = useAppContext();

  const fetchUrl = () =>
    HttpEndpoints.StudentEndpoints.getStudentsForInstructor(userId, {
      includeNextExamDate: true,
      sortBy: StudentSort.PERMIT_EXPIRATION_DATE,
      archived: false,
    });

  const { data: students } = useSWR<StudentWithExamDate[]>(
    fetchUrl,
    authenticatedFetcher,
    {
      fallbackData: [],
    },
  );

  const studentsColumn: Column<StudentWithExamDate>[] = [
    {
      header: t('studentNameColumn'),
      prop: formatNameWithLabel,
    },
    {
      header: t('permitExpirationColumn'),
      prop: (student: StudentWithExamDate) =>
        student.permitExpirationDate &&
        dayjs(student.permitExpirationDate).format(displayDateFormat),
    },
    {
      header: t('examDate'),
      prop: (student: StudentWithExamDate) =>
        student.examDate && dayjs(student.examDate).format(displayDateFormat),
    },
  ];

  return (
    <Panel className="break-inside-avoid">
      <div className="flex items-center overflow-visible">
        <SectionHeading className="flex-grow mb-0 mr-3">
          {t('studentsPriorityTitle')}
        </SectionHeading>
        <DynamicExportButton
          small
          data={students.map((s) => ({
            [t('studentNameColumn')]: formatName(s),
            [t('permitExpirationColumn')]:
              s.permitExpirationDate &&
              dayjs(s.permitExpirationDate).format(displayDateFormat),
            [t('examDate')]:
              s.examDate && dayjs(s.examDate).format(displayDateFormat),
          }))}
          exportFileName={t('studentsPriorityTitle')}
        />
      </div>
      <PagedTable
        fetchUrl={fetchUrl}
        columns={studentsColumn}
        limit={5}
        onError={() => setErrorMessage(t('couldNotFetchMessage'))}
        altText={t('tableAltText')}
        showSearch={false}
        rowLink={({ id }) => Routes.Students.DetailView(id)}
      />
    </Panel>
  );
};
