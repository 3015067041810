import { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';
import { last } from 'lodash';

import useSWR from 'swr';
import { useAppContext } from '../../context/appContext';
import { HttpEndpoints } from '../../data/httpEndpoints';
import { Panel } from '../Common/Panel';
import { SectionHeading } from '../Typography/SectionHeading';
import { Column } from '../Table/SortedFilteredTable/SortedFilteredTable';
import { DashboardComponent, LessonRequest } from '../../typings/backend-types';
import { formatName } from '../../utilities/formatName';
import { PagedTable } from '../Table/PagedTable';
import { useErrorPopupContext } from '../../context/errorPopupContext';
import { displayDayFormat } from '../../utilities/dateFormat';
import { Routes } from '../../utilities/routes';
import useUserPermission from '../../hooks/useUserPermission';
import { Permission, Scope } from '../../typings/roleConfig';
import { DashboardPermissionMissingHint } from './PermissionMissingHint';
import authenticatedFetcher from 'data/authenticatedFetcher';
import { DynamicExportButton } from '@components/Common/ExportImport/DynamicExportButton';

function formatDate(date: string): string {
  return dayjs(date).format(displayDayFormat);
}

export const AllLessonRequestListTile: FunctionComponent = () => {
  const { organizationId } = useAppContext();
  const { t } = useTranslation('translation', {
    keyPrefix: 'lessonRequests.overview',
  });
  const { setErrorMessage } = useErrorPopupContext();
  const fetchUrl = () =>
    HttpEndpoints.LessonRequestEndpoints.getByOrg(organizationId, {
      completed: false,
    });

  const { data: lessonRequests = [] } = useSWR<LessonRequest[]>(
    fetchUrl,
    authenticatedFetcher,
    { fallbackData: [] },
  );

  const allLessonRequestsColumns: Column<LessonRequest>[] = [
    {
      header: t('studentNameColumn'),
      prop: (req: LessonRequest) => formatName(req),
    },
    {
      header: t('instructorColumn'),
      prop: (req: LessonRequest) =>
        formatName(last(req.assignmentHistory)?.assignedTo) || '-',
    },
    {
      header: t('creationDateColumn'),
      prop: (req: LessonRequest) => formatDate(req.createdDate),
    },
  ];

  const canSeeAllLessonRequests = useUserPermission(
    Permission.ALL_LESSON_REQUESTS,
    Scope.READ,
  );

  if (!canSeeAllLessonRequests) {
    return (
      <DashboardPermissionMissingHint
        dashboardComponent={DashboardComponent.ALL_LESSON_REQUESTS}
      />
    );
  }

  return (
    <Panel className="break-inside-avoid">
      <div className="flex items-center">
        <SectionHeading className="flex-grow mb-0 mr-3">
          {t('tableHead')}
        </SectionHeading>
        <DynamicExportButton
          small
          data={lessonRequests.map((req) => ({
            [t('studentNameColumn')]: formatName(req),
            [t('instructorColumn')]:
              formatName(last(req.assignmentHistory)?.assignedTo) || '-',
            [t('creationDateColumn')]: formatDate(req.createdDate),
          }))}
          exportFileName={t('tableHead')}
        />
      </div>
      <PagedTable
        fetchUrl={fetchUrl}
        columns={allLessonRequestsColumns}
        onError={() => setErrorMessage(t('couldNotFetchMessage'))}
        rowLink={(r) => Routes.LessonRequests.DetailView(r.id)}
        limit={5}
        syncPageWithQueryParamKey="lessonRequestPage"
        altText={t('tableAltText')}
        showSearch={false}
      />
    </Panel>
  );
};
