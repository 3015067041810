interface Props<T> {
  items: T[];
  itemHref?: (item: T) => string;
  renderItem: (item: T) => JSX.Element;
  /** color that can be passed in as css background color, e.g. in hex format */
  getItemColor: (item: T) => string;
  getItemInitials: (item: T) => string;
}

export function Feed<T extends { id: string }>({
  items,
  itemHref,
  renderItem,
  getItemColor,
  getItemInitials,
}: Props<T>): JSX.Element {
  return (
    <ul role="list" className="relative z-0 pl-4">
      {items.map((item, index) => {
        return (
          <a key={item.id} href={itemHref?.(item)}>
            <li className={itemHref && 'cursor-pointer'}>
              <div className="relative py-4 font-normal pr-3">
                {index !== items.length - 1 ? (
                  <span
                    className="absolute top-4 left-5 -ml-px h-full w-0.5 bg-gray-300"
                    aria-hidden="true"
                  />
                ) : null}
                <div className="relative flex space-x-3">
                  <div>
                    <span
                      className="h-10 w-10 rounded-full flex items-center justify-center text-white text-sm font-medium select-none"
                      style={{ backgroundColor: getItemColor(item) }}
                    >
                      {getItemInitials(item)}
                    </span>
                  </div>
                  {renderItem(item)}
                </div>
              </div>
            </li>
          </a>
        );
      })}
    </ul>
  );
}
