import dayjs from 'dayjs';
import { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';

import useSWR from 'swr';
import { useAppContext } from '../../context/appContext';
import { useErrorPopupContext } from '../../context/errorPopupContext';
import { HttpEndpoints } from '../../data/httpEndpoints';
import {
  DashboardComponent,
  InvoiceSort,
  InvoiceStatusFilter,
  InvoiceSummary,
} from '../../typings/backend-types';
import { displayDateFormat } from '../../utilities/dateFormat';
import { Routes } from '../../utilities/routes';
import { Panel } from '../Common/Panel';
import { PagedTable } from '../Table/PagedTable';
import { Column } from '../Table/SortedFilteredTable/SortedFilteredTable';
import { SectionHeading } from '../Typography/SectionHeading';
import useContract, { RestrictedFeatures } from '../../hooks/useContract';
import useUserPermission from '../../hooks/useUserPermission';
import { Permission, Scope } from '../../typings/roleConfig';
import { formatNameWithLabel } from '../../utilities/students/formatNameWithLabel';
import { DashboardPermissionMissingHint } from './PermissionMissingHint';
import { DynamicExportButton } from '@components/Common/ExportImport/DynamicExportButton';
import authenticatedFetcher from 'data/authenticatedFetcher';
import { formatName } from 'utilities/formatName';

export const OverdueInvoicesTile: FunctionComponent = () => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'dashboard.overdueInvoices',
  });
  const { organizationId } = useAppContext();
  const { isFeatureEnabled } = useContract();
  const { setErrorMessage } = useErrorPopupContext();

  const fetchUrl = () =>
    HttpEndpoints.InvoiceEndpoints.getInvoicesForOrganization(organizationId, {
      status: InvoiceStatusFilter.OVERDUE,
      sort: { sortBy: InvoiceSort.DUE_DATE },
    });

  const { data: invoices } = useSWR<InvoiceSummary[]>(
    fetchUrl,
    authenticatedFetcher,
    { fallbackData: [] },
  );

  const columns: Column<InvoiceSummary>[] = [
    {
      header: '#',
      prop: (i) => i.invoice_number,
    },
    {
      header: t('columns.studentName'),
      prop: (i) => formatNameWithLabel(i.student),
    },
    {
      header: t('columns.dueDate'),
      prop: (i) => dayjs(i.payment_due_date).format(displayDateFormat),
    },
  ];

  const canSeeInvoices = useUserPermission(Permission.ALL_INVOICES, Scope.READ);

  if (!canSeeInvoices) {
    return (
      <DashboardPermissionMissingHint
        dashboardComponent={DashboardComponent.OVERDUE_INVOICES}
      />
    );
  }

  return (
    <Panel className="break-inside-avoid">
      <div className="flex items-center">
        <SectionHeading className="flex-grow mb-0 mr-3">
          {t('tableHeader')}
        </SectionHeading>
        <DynamicExportButton
          small
          data={invoices.map((i) => ({
            ['#']: i.invoice_number,
            [t('columns.studentName')]: formatName(i.student),
            [t('columns.dueDate')]: dayjs(i.payment_due_date).format(
              displayDateFormat,
            ),
          }))}
          exportFileName={t('tableHeader')}
        />
      </div>
      <PagedTable
        fetchUrl={fetchUrl}
        columns={columns}
        limit={5}
        syncPageWithQueryParamKey="overdueInvoicesPage"
        onError={() => setErrorMessage(t('couldNotFetchMessage'))}
        altText={t('tableAltText')}
        showSearch={false}
        rowLink={(invoice) => {
          if (!isFeatureEnabled(RestrictedFeatures.FINANCE_OVERVIEW)) {
            return Routes.Students.Invoices(invoice.student.id).DetailView(
              invoice.id,
            );
          }
          return Routes.Invoices.DetailView(invoice.id);
        }}
      />
    </Panel>
  );
};
